export const environment = {
  production: true,
  test: false,
  commitVersion: '20241007.3',
  urlBase: 'https://rm.laas.intel.com/',
  appInsights: {
    instrumentationKey: '5ef6b28a-3e7d-4476-9341-f027c31d85c9',
    connectionString: 'InstrumentationKey=5ef6b28a-3e7d-4476-9341-f027c31d85c9;IngestionEndpoint=https://westus2-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/'
  },
  rmAccess:[
    "RM_Admins",
    "RM_L1_Technicians",
    "RM_L2_Technicians",
    "RM_Lab_Customers"
 ],
  services:{
    environmentPrefix: 'https://laas-aks-prod01.laas.icloud.intel.com/',
    FrontEndComposerUrl: 'FrontendComposer/',
    GraphUsersUrl: 'https://laas-aks-test01.laas.icloud.intel.com/rmauthentication/',
    ImageMetadataUrl: 'https://graph.microsoft.com/v1.0/me/photo/',
    SearchUrl: 'rmrecipe/api',
    FilesUrl: 'https://laas-aks-prod01.laas.icloud.intel.com/rmfile/api/RMFileService',
    CreateUrl: 'rmcreate/api',
    HsdEsUrl: 'https://gts2.intel.com/#/newticket?',
GtsSupportURL: 'https://hsdes.intel.com/appstore/gts/#/newticket?ticket_category=Tool%20Support&site=Penang&service_type=tool&site_lab_org=Penang&org_unit=IVE.Labs&component=tool.Recipe%20Management&tool_org_unit=VLA&program=Non%20Program%20Specific',
    HsdEsWebUrl: 'https://hsdes.intel.com/appstore/',
    ThirdPartyAppUrl: 'rmthirdpartytools/api',
    RMHelpUrl: 'https://wiki.ith.intel.com/display/RM/Release+Details',
    RMTicketsUrl: 'https://hsdes.intel.com/appstore/community/#/1204037988?queryId=15014193547'

  }
};